import * as Ariakit from '@ariakit/react'
import classnames from 'classnames'
import * as React from 'react'

import { Button } from '../button'
import { useNotification } from './hooks'

export enum NotificationTheme {
  default,
  success,
  failure,
}

interface NotificationProps {
  className?: string
  /**
   * Default button text
   * @default Close
   */
  closeText?: string

  theme?: NotificationTheme

  /**
   * Custom buttons. Overrides `closeText`
   */
  actions?: JSX.Element

  /**
   * When set, the notification will automatically close
   */
  timeoutInMs?: number
}

export function Notification({
  className,
  closeText = 'Close',
  actions,
  timeoutInMs,
  theme = NotificationTheme.default,
  children,
}: React.PropsWithChildren<NotificationProps>) {
  const notification = useNotification()

  React.useEffect(() => {
    let timeoutId: number
    if (timeoutInMs) {
      timeoutId = window.setTimeout(() => {
        notification.hide()
      }, timeoutInMs)
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [notification, timeoutInMs])

  return (
    <div
      className={classnames(
        className,
        'pointer-events-auto flex space-x-4 rounded-md border border-transparent px-3 py-2 shadow-modal dark:shadow-modal-dark',
        {
          'bg-gray-800 dark:border-gray-600': theme === NotificationTheme.default,
          'bg-green-500 text-green-100 dark:border-green-500 dark:bg-green-800': theme === NotificationTheme.success,
          'bg-red-500 text-red-100 dark:border-red-500 dark:bg-red-800': theme === NotificationTheme.failure,
        }
      )}
    >
      <div className="text-left text-white">{children}</div>
      <div>
        {actions ?? (
          <Button as={Ariakit.DialogDismiss} theme="dark">
            {closeText}
          </Button>
        )}
      </div>
    </div>
  )
}
