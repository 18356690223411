import copy from 'copy-to-clipboard'
import React from 'react'

import { Button } from '../button'
import { InputProps, Textarea } from '../input'

interface Props extends Pick<InputProps, 'label'> {
  className?: string
  text: string
  bottomLeftContent?: React.ReactNode
}

export const CopyToClipboard: React.FC<Props> = ({ className, label, text, bottomLeftContent }) => {
  const [isCopied, setIsCopied] = React.useState(false)

  const handleCopyToClipboard = React.useCallback(() => {
    setIsCopied(true)
    copy(text)
  }, [text])

  React.useEffect(() => {
    let timeout: number
    const resetTimer = () => {
      if (!timeout) return
      clearTimeout(timeout)
    }
    if (isCopied) {
      resetTimer()
      timeout = window.setTimeout(() => {
        setIsCopied(false)
      }, 2000)
    }

    return () => {
      resetTimer()
    }
  }, [isCopied])

  return (
    <div className={className}>
      <Textarea
        label={label}
        readOnly
        onClick={(e) => e.currentTarget.select()}
        inputClass="h-12"
        data-testid="copy-to-clipboard-textarea"
        value={text}
      />
      <div className="mt-1 flex flex-wrap">
        {bottomLeftContent && <div>{bottomLeftContent}</div>}
        <Button
          className="ml-auto flex-none"
          theme="blue"
          onClick={handleCopyToClipboard}
          disabled={isCopied}
          leftContent={isCopied ? 'checkboxTick' : undefined}
          data-testid="copy-to-clipboard-copy-button"
        >
          {isCopied ? 'Copied' : 'Copy'}
        </Button>
      </div>
    </div>
  )
}
