import { Icon } from '../icon'
import { HSVA, hexStringToHSVA } from './util'

const DEFAULT_COLORS = [
  { title: 'Rose', value: '#f43f5e' },
  { title: 'Pink', value: '#db2777' },
  { title: 'Fuchsia', value: '#c026d3' },
  { title: 'Purple', value: '#9333ea' },
  { title: 'Violet', value: '#7c3aed' },
  { title: 'Indigo', value: '#4f46e5' },
  { title: 'Blue', value: '#2563eb' },
  { title: 'Light Blue', value: '#0284c7' },
  { title: 'Cyan', value: '#0891b2' },
  { title: 'Teal', value: '#0d9488' },
  { title: 'Emerald', value: '#059669' },
  { title: 'Green', value: '#16a34a' },
  { title: 'Lime', value: '#65a30d' },
  { title: 'Yellow', value: '#eab308' },
  { title: 'Amber', value: '#d97706' },
  { title: 'Red', value: '#dc2626' },
]

interface ColorItem {
  /**
   * Name of the color
   */
  title: string

  /**
   * Hex value
   */
  value: string
}

interface ColorPaletteProps {
  /**
   * Current selected color in HEX format (supports HEX8 for alpha)
   */
  color: string

  /**
   * List of colors to display in the palette
   *
   * @default DEFAULT_COLORS
   */
  colors?: ColorItem[]

  /**
   * Callback when user picks a color
   */
  onChange: (color: string, originalColor: HSVA) => void
}

export function ColorPalette({ color, colors = DEFAULT_COLORS, onChange }: ColorPaletteProps) {
  const lowerCaseColor = color.toLowerCase()

  return (
    <div className="grid grid-cols-8 gap-3" data-testid="color-palette">
      {colors.map((colorObj) => {
        const isSelected = lowerCaseColor.startsWith(colorObj.value)
        return (
          <div
            key={colorObj.value}
            className="flex h-5 w-full items-center justify-center rounded"
            title={colorObj.title}
            style={{ backgroundColor: colorObj.value }}
            onClick={() => onChange(colorObj.value, hexStringToHSVA(colorObj.value))}
          >
            {isSelected && <Icon name="checkboxTick" className="text-gray-50" />}
          </div>
        )
      })}
    </div>
  )
}
